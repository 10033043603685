<template>
  <div class="container">
    <b-button
      v-if="!showConfirm"
      cy-id="pw-button"
      @click="handleClick"
      :disabled="disabled"
      :variant="className"
      :size="size"
    >
      <i v-if="!spinner && icon" class="fa" :class="[icon, { 'mr-2': hasLabel }]"></i>
      <span v-if="spinner" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
      <slot></slot>
    </b-button>
    <span v-show="showConfirm">
      <span class="mr-1">
        {{ confirmText }}
      </span>
      <button type="button" class="btn btn-success mr-1 my-1" @click="confirm" cy-id="yes">
        {{ $t("yes") }}
      </button>
      <button type="button" class="btn btn-danger mr-1 my-1" @click="cancel" cy-id="no">
        {{ $t("no") }}
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: "CheckButton",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String
    },
    requireConfirm: {
      type: Boolean,
      default: false
    },
    confirmText: {
      type: String,
      default: function () {
        return this.$t("areYouSure");
      }
    },
    spinner: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "md"
    },
    className: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showConfirm: false
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
      this.showConfirm = false;
    },
    confirm() {
      this.$emit("accepted");
      this.showConfirm = false;
    },
    handleClick() {
      if (this.requireConfirm) {
        this.toggleConfirm();
      } else {
        this.confirm();
      }
    },
    toggleConfirm() {
      this.showConfirm = !this.showConfirm;
    }
  },
  computed: {
    hasLabel() {
      return !!(this.$slots.default && this.$slots.default[0].text);
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  display: inline;
  margin: 0;
  padding: 0;
}
</style>
