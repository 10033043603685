import { BaseModel } from "@/models/BaseModel";
import { maxLength, required } from "vuelidate/lib/validators";
import { Person } from "@/models/Person";
import { Info } from "@/models/Info";
import { Protocol } from "@/models/Protocol";
import moment from "moment";
export class Signature extends BaseModel {
  constructor({
    id = 0,
    approved,
    comment,
    creation_date,
    last_viewed,
    last_saved,
    attached_info,
    attached_protocol,
    person,
    company,
    signature = null,
    name_clarification,
    signed_date,
    type
  }) {
    super();
    this.id = id;
    this.approved = approved;
    this.comment = comment;
    this.creation_date = creation_date;
    this.last_viewed = last_viewed;
    this.last_saved = last_saved;
    this.person = person ? new Person(person) : null;
    this.attached_info = attached_info ? new Info(attached_info) : null;
    this.attached_protocol = attached_protocol ? new Protocol(attached_protocol) : null;
    this.company = company;
    this.signature = signature;
    this.name_clarification = name_clarification;
    this.signed_date = signed_date;
    this.type = type;
  }
  static get validations() {
    return validations;
  }

  isSigned() {
    return (this.signature != null && this.signature != "");
  }
}

export default Signature;
