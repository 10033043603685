import { required } from "vuelidate/lib/validators";
import { BaseModel } from "@/models/BaseModel";
import { Company } from "@/models/Company";
import { CompetenceCategory } from "@/models/CompetenceCategory";
import { PersonCompetence } from "@/models/PersonCompetence";
import { RoleCompetence } from "@/models/RoleCompetence";
import { Protocol } from "@/models/Protocol";

export class Competence extends BaseModel {
  constructor({
    id = null,
    name,
    comment,
    remote,
    consequence = 1,
    person_count,
    person_count_manual,
    company = {},
    category = null,
    person_competencies = [],
    role_competencies = [],
    min_person_count = 1,
    priority,
    consequence_info,
    alternative_info,
    risk_info,
    other,
    position,
    is_external,
    protocol = null
  }) {
    super();

    this.id = id;
    this.name = name;
    this.comment = comment;
    this.remote = remote;
    this.consequence = consequence;
    this.person_count = person_count;
    this.person_count_manual = person_count_manual;
    this.company = new Company(company);
    this.category = category ? new CompetenceCategory(category) : null;
    this.min_person_count = min_person_count;
    this.priority = priority;
    this.consequence_info = consequence_info;
    this.alternative_info = alternative_info;
    this.risk_info = risk_info;
    this.other = other;
    this.person_competencies = person_competencies.map((pc) => new PersonCompetence(pc));
    this.role_competencies = role_competencies.map((rc) => new RoleCompetence(rc));
    this.position = position;
    this.is_external = is_external;
    this.protocol = protocol ? new Protocol(protocol) : null;
  }

  static get validations() {
    const validations = {
      name: {
        required
      },
      consequence: {
        required
      }
    };

    return validations;
  }

  get usedPersonCount() {
    return this.person_count > 0 ? this.person_count : this.person_count_manual;
  }

  get usedPriority() {
    return this.priority > 0 ? this.priority : 4;
  }

  get riskLevel() {
    let riskLevel;

    if (this.category && this.category.is_external) {
      if (this.consequence === 3 && (this.usedPriority >= 3 || this.person_count_manual < this.min_person_count)) {
        riskLevel = 4; // Critical
      } else if (
        this.consequence === 3 &&
        (this.usedPriority >= 2 || this.person_count_manual < this.min_person_count)
      ) {
        riskLevel = 3; // High
      } else if (
        this.consequence >= 2 &&
        (this.usedPriority >= 2 || this.person_count_manual <= this.min_person_count)
      ) {
        riskLevel = 2; // Medium
      } else {
        riskLevel = 1; // Low
      }
    } else {
      if (this.consequence === 3 && (this.usedPriority >= 3 || this.usedPersonCount < this.min_person_count)) {
        riskLevel = 4; // Critical
      } else if (this.consequence === 3 && (this.usedPriority >= 2 || this.usedPersonCount < this.min_person_count)) {
        riskLevel = 3; // High
      } else if (this.consequence >= 2 && (this.usedPriority >= 2 || this.usedPersonCount <= this.min_person_count)) {
        riskLevel = 2; // Medium
      } else {
        riskLevel = 1; // Low
      }
    }

    return riskLevel;
  }

  get priorityRiskLevel() {
    let riskLevel;

    if (this.usedPersonCount < 2 && this.priority === 3) {
      riskLevel = 4; // Critical
    } else if (this.priority === 3) {
      riskLevel = 3; // High
    } else if (this.priority === 2) {
      riskLevel = 2; // Medium
    } else {
      riskLevel = 1; // Low
    }

    return riskLevel;
  }
}

export default Competence;
