import { Company } from "@/models/Company";

export const CompanyRequest = (argCompany) => {
  let company = argCompany.copy();

  if (!company.id) {
    delete company.id;
  }

  if (company.logo) {
    company.logo = company.logo.id;
  }

  if (company.subscriptions) {
    company.subscriptions = company.subscriptions.map((s) => s.id);
  }

  if (company.whistleblower_partner) {
    company.whistleblower_partner = company.whistleblower_partner.id;
  }

  if (company.hseq_responsible) {
    company.hseq_responsible = company.hseq_responsible.id;
  }
  return company;
};

export const CompanyResponse = (company) => {
  return new Company(company);
};

export const CompaniesResponse = (companies) => {
  return companies.map((c) => CompanyResponse(c));
};
