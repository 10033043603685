import BaseService from "./BaseService";
import { UserGroupRequest, UserGroupResponse, UserGroupsResponse } from "./mappers/UserGroupMapper";

class UserGroupService extends BaseService {
  constructor() {
    super();
  }

  createUserGroup(data) {
    return this.post(`usergroups/`, data)
      .then((response) => response.data)
      .then((data) => UserGroupResponse(data));
  }

  createCompanyUserGroup(id, data) {
    return this.post(`companies/${id}/usergroups/`, UserGroupRequest(data))
      .then((response) => response.data)
      .then((data) => UserGroupResponse(data));
  }

  getUserGroupsByCompany(id) {
    return this.get(`companies/${id}/usergroups/`)
      .then((response) => response.data)
      .then((data) => UserGroupsResponse(data));
  }

  getUserGroup(id) {
    return this.get(`usergroups/${id}/`)
      .then((response) => response.data)
      .then((data) => UserGroupResponse(data));
  }

  getUserGroups() {
    return this.get(`usergroups/`)
      .then((response) => response.data)
      .then((data) => UserGroupsResponse(data));
  }

  updateUserGroup(id, data) {
    return this.patch(`usergroups/${id}/`, UserGroupRequest(data))
      .then((response) => response.data)
      .then((data) => UserGroupResponse(data));
  }

  deleteUserGroup(id) {
    return this.delete(`usergroups/${id}/`);
  }
}

export default new UserGroupService();
