import moment from "moment";
import BaseModel from "@/models/BaseModel";
import Document from "@/models/Document";
import Person from "@/models/Person";
import Refresher from "@/models/Refresher";

export class PersonRefresher extends BaseModel {
  constructor({ id = null, level, person, refresher, performed_date, scheduled_date, deadline_date, documents = [] }) {
    super();

    this.id = id;
    this.level = level;
    this.person = person ? new Person(person) : null;
    this.refresher = refresher ? new Refresher(refresher) : null;
    this.performed_date = performed_date;
    this.scheduled_date = scheduled_date;
    this.deadline_date = deadline_date;
    this.documents = documents.map((d) => new Document(d));

    this._showDetails = false; // Used in b-tables to expand row
  }

  set_deadline_date(interval) {
    if(interval > 0)
    {
      this.deadline_date = moment(this.performed_date).add(interval, "days").format("YYYY-MM-DD");
    }
  }
}

export default PersonRefresher;
