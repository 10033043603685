<script>
import moment from "moment";

export default {
  methods: {
    isDeadlineLate(date) {
      if (moment(date).isBefore(moment(), "day")) {
        return "danger";
      }

      return "light";
    }
  }
};
</script>
