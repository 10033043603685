import { BaseModel } from "@/models/BaseModel";
import { Person } from "@/models/Person";
import { Protocol } from "@/models/Protocol";
import { THREAT_RISK_TYPES } from "@/constants";

export class Threat extends BaseModel {
  constructor({
    id = null,
    creation_date,
    name,
    responsible_text,
    responsible_person,
    threat_type,
    consequence = 1,
    status = 1,
    probability = 1,
    parent = null,
    children = [],
    threat_actions = [],
    comment,
    actions,
    prevention,
    report,
    monitoring,
    training,
    protocol = null
  }) {
    super();

    this.id = id;
    this.creation_date = creation_date;
    this.name = name;
    this.responsible_text = responsible_text;
    this.responsible_person = responsible_person ? new Person(responsible_person) : null;
    this.threat_type = threat_type;
    this.consequence = consequence;
    this.status = status;
    this.probability = probability;
    this.parent = parent;
    this.children = children;
    this.threat_actions = threat_actions;
    this.comment = comment;
    this.actions = actions;
    this.prevention = prevention;
    this.report = report;
    this.monitoring = monitoring;
    this.training = training;
    this.protocol = protocol ? new Protocol(protocol) : null;

    this._showResponsibleSelect =
      this.isNew || this.responsible_person || (!this.responsible_person && !this.responsible_text); // Keep track of manual switching of show responsible person
  }

  get riskLevel() {
    const sum = parseInt(this.consequence) + parseInt(this.probability);
    let riskLevel;

    if (sum <= 4) {
      riskLevel = THREAT_RISK_TYPES.LOW;
    } else if (sum <= 5) {
      riskLevel = THREAT_RISK_TYPES.MEDIUM;
    } else if (sum <= 7) {
      riskLevel = THREAT_RISK_TYPES.HIGH;
    } else {
      riskLevel = THREAT_RISK_TYPES.CRITICAL;
    }

    return riskLevel;
  }

  get isLowRisk() {
    return this.riskLevel === THREAT_RISK_TYPES.LOW;
  }

  get isMediumRisk() {
    return this.riskLevel === THREAT_RISK_TYPES.MEDIUM;
  }

  get isHighRisk() {
    return this.riskLevel === THREAT_RISK_TYPES.HIGH;
  }

  get isCriticalRisk() {
    return this.riskLevel === THREAT_RISK_TYPES.CRITICAL;
  }

  get showResponsibleSelect() {
    return this._showResponsibleSelect;
  }

  toggleShowResponsibleSelect() {
    this._showResponsibleSelect = !this._showResponsibleSelect;
  }
}

export default Threat;
