import axios from "axios";
import ErrorInterceptor from "./ErrorInterceptor";
import ResponseInterceptor from "./ResponseInterceptor";
import RequestInterceptor from "./RequestInterceptor";

export const setup = () => {
  axios.interceptors.request.use(RequestInterceptor);
  axios.interceptors.response.use(ResponseInterceptor, ErrorInterceptor);
};

export default setup;
