import { User } from "@/models/User";
import { PwGroup } from "@/models/PwGroup";

export const UserRequest = (argUser) => {
  let user = argUser.copy();

  if (!user.id) {
    delete user.id;
  }

  for (let i = 0; i < user.pwgroups.length; i++) {
    user.pwgroups[i] = user.pwgroups[i].id;
  }

  return user;
};

export const UserResponse = (user) => {
  return new User(user);
};

export const UsersResponse = (user) => {
  return user.map((p) => UserResponse(p));
};

export const PwGroupResponse = (pwgroup) => {
  return new PwGroup(pwgroup);
};

export const PwGroupsResponse = (pwgroup) => {
  return pwgroup.map((p) => PwGroupResponse(p));
};
