import { BaseModel } from "@/models/BaseModel";

export class QuestionnaireCategory extends BaseModel {
  constructor({ id = null, name, company, questionnaire_type }) {
    super();

    this.id = id;
    this.name = name;
    this.company = company;
    this.questionnaire_type = questionnaire_type;
  }

  get isCreatedBySystem() {
    return !this.company;
  }
}

export default QuestionnaireCategory;
