import BaseService from "./BaseService";
import { CompanyRequest, CompanyResponse, CompaniesResponse } from "./mappers/CompanyMapper";
import store from "@/store";

class CompanyService extends BaseService {
  constructor() {
    super();
  }

  getCompany(id) {
    return this.get(`companies/${id}/`)
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  getCompanybyWhistleblowerReference(id) {
    return this.get(`companies/whistleblowerreport/${id}/`)
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  getWhistleblowerPartnerCompanies() {
    return this.get(`whistleblowerpartners/`)
      .then((response) => response.data)
      .then((data) => CompaniesResponse(data));
  }

  getMyCompany() {
    return this.get(`my/company/`)
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  getMyAvailableCompanies() {
    return this.get(`my/available_companies/`)
      .then((response) => response.data)
      .then((data) => CompaniesResponse(data));
  }

  getCompanies() {
    return this.get(`companies/`)
      .then((response) => response.data)
      .then((data) => CompaniesResponse(data));
  }

  updateCompany(id, data) {
    return this.patch(`companies/${id}/`, CompanyRequest(data))
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  updateMyCompany(data) {
    return this.patch(`my/company/`, CompanyRequest(data));
  }

  SetActiveCompany(data) {
    store.dispatch("app/clearData");
    return this.post("setActiveCompany", CompanyRequest(data))
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  setAdvancedMode(advancedMode) {
    return this.patch(`my/company/`, { advanced_mode: advancedMode });
  }

  getPartnerByPartnerRef(partnerRef) {
    return this.get(`companies/partners/${partnerRef}/`)
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  getRecruitedCompaniesByMyCompany() {
    return this.get(`my/company/recruited-companies/`)
      .then((response) => response.data)
      .then((data) => CompaniesResponse(data));
  }

  getRecruitedCompanyByMyCompany(id) {
    return this.get(`my/company/recruited-companies/${id}/`)
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }

  getDaughterCompaniesByMyCompany() {
    return this.get(`my/company/daughter-companies/`)
      .then((response) => response.data)
      .then((data) => CompaniesResponse(data));
  }

  getDaughterCompanyByMyCompany(id) {
    return this.get(`my/company/daughter-companies/${id}/`)
      .then((response) => response.data)
      .then((data) => CompanyResponse(data));
  }
}

export default new CompanyService();
