import { BaseModel } from "@/models/BaseModel";

export class CompetenceCategory extends BaseModel {
  constructor({ id = null, name, company, is_external }) {
    super();

    this.id = id;
    this.name = name;
    this.company = company;
    this.is_external = is_external;
  }

  get isCreatedBySystem() {
    return !this.company;
  }
}

export default CompetenceCategory;
