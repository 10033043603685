import axios from "axios";
import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import AzureAuthService from "@/services/AzureAuthService";
import CompanyService from "../services/CompanyService";

export default {
  namespaced: true,
  state: {
    user: null,
    selectedCompanyId2: null,
    availableCompanies: []
  },
  mutations: {
    logout(state) {
      state.user = null;
    },
    setUser: (state, value) => {
      state.user = value;
      if (state.user.company.color == "" || state.user.company.color == null) {
        document.documentElement.style.setProperty("--primary", '#33a646');
      }
      else {
        document.documentElement.style.setProperty("--primary", state.user.company.color);

      }
    },
    setSelectedCompanyId2(state, id) {
      state.selectedCompanyId2 = id;
    },
    setAvailableCompanies(state, value) {
      state.availableCompanies = value;
    }
  },
  actions: {
    initStore({ commit, state, dispatch }) {
      return new Promise((resolve, reject) => {
        if (!state.availableCompanies) {

        }

        if (!state.user && localStorage.getItem("user")) {
          CompanyService.getMyAvailableCompanies().then((companies) => {
            commit("setAvailableCompanies", companies);
          });
          UserService.getMyProfile().then((user) => {
            commit("setUser", user);

            dispatch("app/setFrontendLanguage", user.active_language, {
              root: true
            });

            dispatch("app/setBackendLanguage", user.active_language, {
              root: true
            });

            resolve();
          });

        } else {
          resolve();
        }
      });
    },
    setUser({ commit }, value) {
      commit("setUser", value);
      localStorage.setItem("user", JSON.stringify(value));
    },
    logout({ commit }) {
      if (localStorage.getItem("azureUser") == "true") {
        commit("logout");
        delete axios.defaults.headers.common["Authorization"];
        commit("setSelectedCompanyId2", "");
        commit("setAvailableCompanies", []);
        localStorage.clear();
        return AzureAuthService.logout().then(() => {

        });
      }
      else {
        return AuthService.logout().then(() => {
          commit("logout");
          delete axios.defaults.headers.common["Authorization"];
          commit("setSelectedCompanyId2", "");
          localStorage.clear();
        });
      }
    },
    setSelectedCompanyId2({ commit }, id) {
      commit("setSelectedCompanyId2", id);
    },
    setAvailableCompanies({ commit }, value) {
      commit("setAvailableCompanies", value);
    },
    clear({ commit }) {
      commit("logout");
      delete axios.defaults.headers.common["Authorization"];
      commit("setSelectedCompanyId2", "");
      commit("setAvailableCompanies", []);
      localStorage.clear();
    }
  },
  getters: {
    company: (state) => {
      return state.user.company;
    },
    user: (state) => {
      let user = state.user;
      return user;
    },
    selectedCompanyId2: (state) => {
      return state.selectedCompanyId2;
    },
    availableCompanies: (state) => {
      return state.availableCompanies;
    }
  }
};
