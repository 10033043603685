import { BaseModel } from "@/models/BaseModel";
import { Permission } from "@/models/Permission";

export class Group extends BaseModel {
  constructor({ name = "", permissions = [] }) {
    super();

    this.name = name;
    this.permissions = permissions.map((p) => new Permission(p));
  }

  hasPermission(permission) {
    return this.permissions.some((p) => p.codename === permission);
  }
}

export default Group;
