import { BaseModel } from "@/models/BaseModel";
import { Info } from "@/models/Info";
import { Protocol } from "@/models/Protocol";
import { Questionnaire } from "@/models/Questionnaire";

export class Tag extends BaseModel {
  constructor({ id = null, name, color, company, infos = [], protocols = [], questionnaires = [], activities = [] }) {
    super();

    this.id = id;
    this.name = name;
    this.color = color;
    this.company = company;
    this.infos = infos.map((x) => new Info(x));
    this.protocols = protocols.map((x) => new Protocol(x));
    this.questionnaires = questionnaires.map((x) => new Questionnaire(x));
    this.activities = activities.map((x) => new Activity(x));
  }

  get isCreatedBySystem() {
    return !this.company;
  }
}

export default Tag;
