import { maxLength, required } from "vuelidate/lib/validators";
import { BaseModel } from "@/models/BaseModel";
import { Company } from "@/models/Company";
import { PersonCompetence } from "@/models/PersonCompetence";
import { PersonRefresher } from "@/models/PersonRefresher";
import { User } from "@/models/User";
import { Info } from "@/models/Info";
import Department from "@/models/Department";

export class Person extends BaseModel {
  constructor({
    id = null,
    name,
    comment,
    company = {},
    person_competencies = [],
    person_refreshers = [],
    jobdescriptions = [],
    departments = [],
    closest_boss,
    immediate_manager,
    role,
    user = null,
    is_active,

    birth_date,
    phone,
    email,
    address,
    zip,
    city,
    relatives,

    field1,
    field2,
    field3,
    field4,
    field5
  }) {
    super();

    this.id = id;
    this.name = name;
    this.comment = comment;
    this.company = new Company(company);
    this.person_competencies = person_competencies.map((pc) => new PersonCompetence(pc));
    this.person_refreshers = person_refreshers.map((pr) => new PersonRefresher(pr));
    this.jobdescriptions = jobdescriptions.map((i) => new Info(i));
    this.departments = departments.map((d) => new Department(d));
    this.closest_boss = closest_boss;
    this.immediate_manager = immediate_manager ? new Person(immediate_manager) : null;
    this.role = role;
    this.user = user ? new User(user) : null;
    this.is_active = is_active;
    this.birth_date = birth_date;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.zip = zip;
    this.city = city;
    this.relatives = relatives;

    this.field1 = field1;
    this.field2 = field2;
    this.field3 = field3;
    this.field4 = field4;
    this.field5 = field5;
  }

  static get validations() {
    const validations = {
      name: {
        maxLength: maxLength(255)
      },
      role: {
        maxLength: maxLength(255)
      },
      closest_boss: {}
    };

    return validations;
  }

  get fullName() {
    return this.user ? this.user.fullName : this.name;
  }

  get hasUser() {
    return !!this.user;
  }

  get isAdmin() {
    return this.user && this.user.isAdmin;
  }

  get getDepartmentsAsString() {
    var s = "";

    for (var i = 0; i < this.departments.length; i++) {
      if (i != 0) {
        s = s + ", ";
      }
      s = s + this.departments[i].name;
    }

    return s;
  }
}

export default Person;
