import { Activity } from "@/models/Activity";
import { BaseModel } from "@/models/BaseModel";
import { Document } from "@/models/Document";
import { Protocol } from "@/models/Protocol";
import { Question } from "@/models/Question";
import { Info } from "@/models/Info";
import { maxLength } from "vuelidate/lib/validators";
import moment from "moment";
import { PROTOCOL_STATUS_TYPES } from "../constants";

export class Answer extends BaseModel {
  constructor({
    id = null,
    comment = null,
    value_text,
    value_number = null,
    value_option = null,
    value_date = null,
    protocol,
    question,
    documents = [],
    activities = [],
    info_siblings = [],
    creation_date,
    latest_change,
    deviation_protocols,
    risk_type,
    risk_consequence,
    risk_probability,
    description,
    solved,
    solution,
    needs_immediate_solution,
    answered_by
  }) {
    super();

    this.id = id;
    this.comment = comment;
    this.value_text = value_text;
    this.value_option = value_option;
    this.value_date = value_date;
    this.protocol = protocol ? new Protocol(protocol) : null;
    this.question = new Question(question);
    this.documents = documents.map((d) => new Document(d));
    this.activities = activities.map((a) => new Activity(a));
    this.info_siblings = info_siblings.map((i) => new Info(i));
    this.value_number = value_number ? parseFloat(value_number) : null;
    this.creation_date = moment(creation_date);
    this.latest_change = moment(latest_change);
    this.deviation_protocols = deviation_protocols ? deviation_protocols : null;
    this.risk_type = risk_type;
    this.risk_consequence = risk_consequence;
    this.risk_probability = risk_probability;
    this.description = description
    this.solved = solved;
    this.solution = solution;
    this.needs_immediate_solution = needs_immediate_solution;
    this.answered_by = answered_by;


    if (this.value_number && this.question.isRange()) {
      this.value_number = Math.round(this.value_number, 0);
    }
  }

  static get validations() {
    const validations = {
      comment: {},
      value_date: {},
      value_number: {},
      value_option: {},
      value_text: {}
    };

    return validations;
  }

  get hasActivity() {
    return this.activities && this.activities.length > 0;
  }

  get hasComment() {
    return this.comment && this.comment.length > 0;
  }

  get hasDocument() {
    return this.documents && this.documents.length > 0;
  }

  get hasDeviation() {
    return this.deviation_protocols && this.deviation_protocols.length > 0;
  }

  get hasSiblings() {
    return this.info_siblings && this.info_siblings.length > 0;
  }

  isRequiredAndVisible(protocol) {
    let required = false;


    // Required shall be valid if the question is visible(due to condition, status etc).
    // Required shall be overriden if protocol is draft, unless it is a claim
    if (protocol) {
      if (protocol.isClaim || protocol.status > PROTOCOL_STATUS_TYPES.DRAFT) {
        required = this.question.is_required && this.question.conditionsFulfilled(protocol.answers, protocol.status);
      }
      else {
        required = false;
      }
    }

    return required;
  }


}

export default Answer;
