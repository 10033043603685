import { BaseModel } from "@/models/BaseModel";
import moment from "moment";
import { Company } from "@/models/Company";
import { Person } from "@/models/Person";

export class Department extends BaseModel {
  constructor({
    id = null,
    date = null,
    name = "",
    children = [],
    parent = null,
    company = {},
    boss,
    persons = [],
    safety_representative,
    description = "",
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    field9,
    field10
  }) {
    super();

    this.id = id;
    this.date = date ? moment(date).format("YYYY-MM-DD") : null;
    this.name = name;
    this.children = children.length > 0 ? children.map((c) => new this.constructor(c)) : [];
    this.parent = parent;
    this.company = company ? new Company(company) : null;
    this.boss = boss ? new Person(boss) : null;
    this.persons = persons ? persons.map((p) => new Person(p)) : [];
    this.safety_representative = safety_representative ? new Person(safety_representative) : null;
    this.description = description;
    this.field1 = field1;
    this.field2 = field2;
    this.field3 = field3;
    this.field4 = field4;
    this.field5 = field5;
    this.field6 = field6;
    this.field7 = field7;
    this.field8 = field8;
    this.field9 = field9;
    this.field10 = field10;
  }
}

export default Department;
