import ErrorList from "@/components/ErrorList.vue";
import PwButton from "@/components/PwButton.vue";
import CheckButton from "@/components/CheckButton.vue";
import InfoUserGroupVisibilityChecklist from "@/components/InfoUserGroupVisibilityChecklist";
import Vue from "vue";
import VueQRCodeComponent from '@/components/qrcode';

// Global components that will be accessible in other components with no other import required
Vue.component("error-list", ErrorList);
Vue.component("pw-button", PwButton);
Vue.component("check-button", CheckButton);
Vue.component("info-user-group-visibility-checklist", InfoUserGroupVisibilityChecklist);
Vue.component('qr-code', VueQRCodeComponent);