<template>
  <ul v-if="field.$error">
    <li v-if="invalid('required') && !this.whistleblower">{{ $t("types.validators.required") }}</li>
    <li v-if="invalid('required') && this.whistleblower">{{ $t("types.validators.whistleblowerRequired") }}</li>
    <li v-if="invalid('email')">{{ $t("types.validators.email") }}</li>
    <li v-if="invalid('minLength')">
      {{
        $t("types.validators.minLength", {
          minLength: field.$params.minLength.min
        })
      }}
    </li>
    <li v-if="invalid('maxLength')">
      {{
        $t("types.validators.maxLength", {
          maxLength: field.$params.maxLength.max
        })
      }}
    </li>
    <li v-if="invalid('minValue')">
      {{
        $t("types.validators.minValue", {
          minValue: field.$params.minValue.min
        })
      }}
    </li>
    <li v-if="invalid('maxValue')">
      {{
        $t("types.validators.maxValue", {
          maxValue: field.$params.maxValue.max
        })
      }}
    </li>
    <li class="error" v-if="invalid('sameAsPassword')">
      {{ $t("types.validators.passwordConfirmation") }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "errorList",
  props: {
    field: {
      type: Object
    },
    //whether error list is on whistleblower report component, which has slightly different error message requirements
    whistleblower: {
      type: Boolean
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    hasValidator(validator) {
      return this.field.hasOwnProperty(validator);
    },
    invalid(validator) {
      return this.hasValidator(validator) && !this.field[validator];
    }
  }
};
</script>

<style scoped lang="scss">
li {
  color: red;
}
</style>
