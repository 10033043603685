<template>
  <div id="container"></div>
</template>

<script>
const QRCode = require("qrcode-svg");
export default {
  props: {
    content: { type: String, required: true },
    size: { type: Number, required: false, default: 256 },
    color: { type: String, required: false, default: "#000" },
    bgColor: { type: String, required: false, default: "#FFF" },
    errorLevel: {
      type: String,
      validator: function (value) {
        return value === "L" || value === "M" || value === "Q" || value === "H";
      },
      required: false,
      default: "H"
    }
  },
  watch: {
    text: function () {
      this.clear();
      this.makeCode(this.text);
    },
    size: function () {
      this.clear();
      this.generateCode();
    }
  },
  data() {
    return {
      qrcode: {},
      svg: null
    };
  },
  mounted() {
    this.qrcode = this.generateCode();
  },
  methods: {
    clear: function () {
      this.$el.innerHTML = "";
    },
    makeCode: function (text) {
      this.qrcode.makeCode(text);
    },

    generateCode: function () {
      this.qrcode = new QRCode({
        content: this.content,
        width: this.size,
        height: this.size,
        color: this.color,
        background: this.bgColor,
        ecl: this.errorLevel
      });
      var svg = this.qrcode.svg();
      document.getElementById("container").innerHTML = svg;
    }
  }
};
</script>
