import { BaseModel } from "@/models/BaseModel";

export class ActivityCategory extends BaseModel {
  constructor({ id = null, name, company }) {
    super();

    this.id = id;
    this.name = name;
    this.company = company;
  }

  get isCreatedBySystem() {
    return !this.company;
  }
}

export default ActivityCategory;
