import moment from "moment";
import { maxLength, required, requiredUnless } from "vuelidate/lib/validators";
import { INFO_STATUS_TYPES } from "@/constants";
import { BaseModel } from "@/models/BaseModel";
import { Activity } from "@/models/Activity";
import { Document } from "@/models/Document";
import { InfoCategory } from "@/models/InfoCategory";
import { Person } from "@/models/Person";
import { User } from "@/models/User";
import { UserGroup } from "@/models/UserGroup";
import { Company } from "@/models/Company";
import { Tag } from "@/models/Tag";
import Department from "./Department";
import Signature from "./Signature";
import store from '@/store';


export class Info extends BaseModel {
  constructor({
    id = null,
    name,
    name_sv,
    name_en,
    content = "<p></p>",
    content_sv = "<p></p>",
    content_en = "<p></p>",
    info_type,
    status = INFO_STATUS_TYPES.DRAFT,
    creation_date,
    deadline_date,
    latest_change,
    approved_timestamp,
    completed_timestamp,
    category = null,
    category2 = null,
    creator,
    company,
    department,
    version = "1",
    responsible,
    only_visible_for_admins = false,
    activities = [],
    activity_count = 0,
    activity_done_count = 0,
    documents = [],
    parent = null,
    children = [],
    origin = null,
    links = [],
    siblings = [],
    language,
    viewed,
    signed,
    signatures = [],
    signature_count,
    signature_approved_count,
    signature_rejected_count,
    next_version = null,
    previous_version = null,
    version_changelog,
    field1,
    field2,
    field3,
    field4,
    field5,
    field6,
    field7,
    field8,
    visible_to = [],
    visible_to_user_groups = [],
    visible_to_departments = [],
    readreceipt_active = false,
    field9,
    field10,
    field11, //Default
    field12, //Sustainability report comment
    field13, //Reviewed signature
    field14, //visable for children
    field15, //cooperation
    field16, //signature
    field17, //links
    field18, //Status history
    field19, //Only use template info
    field20, //template id
    field21,
    field22,
    field23,
    field24,
    field25,
    field26,
    field27,
    field28,
    field29,
    field30,
    role_competencies = [],
    persons = [],
    setTranslationFieldsExplicitly = false,
    external_url,
    prio,
    tags = [],
    esrstags = [],
    esrs_comment,
    esrs_report,
    esrs_control,
    next_deadline_date,
    next_deadline_date2,
    reminder_days_ahead = 30,
    video_content
  }) {
    super();
    this.id = id;
    this.name = name;
    this.name_sv = name_sv;
    this.name_en = name_en;
    this.content = content;
    this.content_sv = content_sv;
    this.content_en = content_en;
    this.info_type = info_type;
    this.status = status;
    this.creation_date = moment(creation_date);
    this.latest_change = moment(latest_change);
    this.deadline_date = deadline_date;
    this.approved_timestamp = approved_timestamp ? moment(approved_timestamp) : null;
    this.completed_timestamp = completed_timestamp ? moment(completed_timestamp) : null;
    this.category = category ? new InfoCategory(category) : null;
    this.category2 = category2 ? new InfoCategory(category2) : null;
    this.creator = creator ? new User(creator) : null;
    this.company = company ? new Company(company) : null;
    this.department = department ? new Department(department) : null;
    this.responsible = responsible ? new Person(responsible) : null;
    this.only_visible_for_admins = only_visible_for_admins;
    this.activities = activities.map((a) => new Activity(a));
    this.activity_count = activity_count;
    this.activity_done_count = activity_done_count;
    this.documents = documents.map((d) => new Document(d));
    this.version = version;
    this.parent = parent ? parent : null;
    this.children = children.map((i) => new Info(i));
    this.origin = origin ? origin : null;
    this.links = links.map((i) => new Info(i));
    this.siblings = siblings.map((i) => new Info(i));
    this.persons = persons.map((p) => new Person(p));
    this.language = language;
    this.viewed = viewed;
    this.signed = signed;
    this.signatures = signatures.map((s) => new Signature(s));
    this.signature_count = signature_count;
    this.signature_approved_count = signature_approved_count;
    this.signature_rejected_count = signature_rejected_count;
    this.next_version = next_version;
    this.previous_version = previous_version;
    this.version_changelog = version_changelog;
    // Generic fields...
    this.field1 = field1;
    this.field2 = field2;
    this.field3 = field3;
    this.field4 = field4;
    this.field5 = field5;
    this.field6 = field6;
    this.field7 = field7;
    this.field8 = field8;
    this.visible_to = visible_to;
    this.visible_to_user_groups = visible_to_user_groups;
    this.visible_to_departments = visible_to_departments ? visible_to_departments.map((d) => new Department(d)) : [];
    this.readreceipt_active = readreceipt_active;
    this.field9 = field9;
    this.field10 = field10;
    this.field11 = field11;
    this.field12 = field12;
    this.field13 = field13;
    this.field14 = field14;
    this.field15 = field15;
    this.field16 = field16;
    this.field17 = field17;
    this.field18 = field18;
    this.field19 = field19;
    this.field20 = field20;
    this.field21 = field21;
    this.field22 = field22;
    this.field23 = field23;
    this.field24 = field24;
    this.field25 = field25;
    this.field26 = field26;
    this.field27 = field27;
    this.field28 = field28;
    this.field29 = field29;
    this.field30 = field30;
    this.role_competencies = role_competencies;
    this.setTranslationFieldsExplicitly = setTranslationFieldsExplicitly;
    this.external_url = external_url;
    this.prio = prio;
    this.tags = tags.map((x) => new Tag(x));
    this.esrstags = esrstags.map((x) => new Tag(x));
    this.esrs_comment = esrs_comment;
    this.esrs_report = esrs_report;
    this.esrs_control = esrs_control;
    this.next_deadline_date = next_deadline_date;
    this.next_deadline_date2 = next_deadline_date2;
    this.reminder_days_ahead = reminder_days_ahead;
    this.video_content = video_content;
  }

  static get validations() {
    const validations = {
      content: {
        requiredIf: this.content_sv == undefined
      },
      content_sv: {},
      content_en: {},
      name: {
        maxLength: maxLength(250),
        required //requiredIf: this.name_sv == undefined
      },
      name_sv: {
        maxLength: maxLength(250)
      },
      name_en: {
        maxLength: maxLength(250)
      },
      responsible: {}
    };

    return validations;
  }

  get history() {
    if (this.field18) {
      return JSON.parse(this.field18);
    } else {
      return [];
    }
  }

  // Used in InfoFormEmployee to determine what type it is
  get employeeFollowUpType() {
    return this.field1;
  }
  set employeeFollowUpType(value) {
    this.field1 = value;
  }

  // Used in InfoFormEmployee to determine what persons that are connected
  get employeeConnectedEmployees() {
    return JSON.parse(this.field3);
  }
  set employeeConnectedEmployees(value) {
    this.field3 = JSON.stringify(value);
  }

  get editAllowed() {
    let user = store.getters["auth/user"];

    if ((user.isCompanyAdmin || (this.responsible != null && user.person == this.responsible.id))) {
      return true;
    }
    else {
      return false;
    }

  }
}

export default Info;
