import { BaseModel } from "@/models/BaseModel";

export class InfoCategory extends BaseModel {
  constructor({ id = null, name, company, info_type }) {
    super();

    this.id = id;
    this.name = name;
    this.company = company;
    this.info_type = info_type;
  }

  get isCreatedBySystem() {
    return !this.company;
  }
}

export default InfoCategory;
