import router from "@/router";
import store from "@/store";
import axios from "axios";
import AzureAuthService from "../AzureAuthService";
import UserService from "../UserService";

export const ErrorInterceptor = async (error) => {

  if (error.response) {

    const originalRequest = error.config;

    if (error.response.status === 403 && !originalRequest._retry) {

      await AzureAuthService.refreshToken();

      if (originalRequest.url == "my/profile/") {
        store.dispatch("app/showValidationError", { response: { data: { error: ["Du har blivit utloggad. Logga in igen"] } } });
        store.dispatch("auth/clear");

        router.push({ name: "login", query: { nextUrl: router.currentRoute.path } });
      }
      else {
        UserService.getMyProfile()
          .then((user) => {
            originalRequest._retry = true;
            return axios(originalRequest);
          }).catch((error) => {
          })
      }
    }
    else if (error.response.status === 401 || error.response.status === 403) {

      if (error.response.data.detail == "Invalid token." || error.response.data.detail == "Ogiltig \"token\".") {

        store.dispatch("app/showValidationError", { response: { data: { error: ["Du har blivit utloggad. Logga in igen"] } } });
        store.dispatch("auth/clear");

        router.push({ name: "login", query: { nextUrl: router.currentRoute.path } });
      }
      else {
        store.dispatch("app/showValidationError", { response: { data: { error: ["Du har inte tillåtelse att se det här"] } } });
      }

    } else if (error.response.status === 400) {
      store.dispatch("app/showValidationError", error);
    }
  } else {
    store.commit("app/setConnected", false);
    return Promise.reject(error);
  }
};

export default ErrorInterceptor;
