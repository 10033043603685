import { BaseModel } from "@/models/BaseModel";
import { Permission } from "@/models/Permission";
import { Group } from "@/models/Group";

export class PwGroup extends BaseModel {
  constructor({ id = null, group = "" }) {
    super();

    this.id = id;
    this.group = new Group(group);
  }

  hasPermission(permission) {
    return this.group.permissions.some((p) => p.codename === permission);
  }
}

export default PwGroup;
