import BaseModel from "@/models/BaseModel";
import Competence from "@/models/Competence";
import Info from "@/models/Info";

export class RoleCompetence extends BaseModel {
  constructor({ id = null, level, competence, role }) {
    super();

    this.id = id;
    this.level = level;
    this.competence = competence ? new Competence(competence) : null;
    this.role = role ? new Info(role) : null;
  }
}

export default RoleCompetence;
