import BaseService from "./BaseService";
import { InfoRequest, InfoResponse, InfosResponse, PaginatedInfosResponse } from "./mappers/InfoMapper";
import store from "@/store";

class InfoService extends BaseService {
  constructor() {
    super();
  }

  createInfo(data) {
    store.dispatch("app/setPendingChanges", false);
    return this.post(`infos/`, InfoRequest(data))
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  createCompanyInfo(id, data) {
    store.dispatch("app/setPendingChanges", false);
    return this.post(`companies/${id}/infos/`, InfoRequest(data))
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  getInfosByCompany(id, page = 1, pageSize = 5, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }
  getInfosByCompanyAndStatus(
    id,
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false,
    status = []) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?page=${page}&page_size=${pageSize}&search=${search}${ordering}&status=${status}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  async getInfosByCompanyAndOrigin(id, origin, page = 1, pageSize = 5, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?origin=${origin}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByCompanyAndResponsible(
    id,
    responsible = "",
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?page=${page}&page_size=${pageSize}&search=${search}${ordering}&responsible__id=${responsible}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosOfRecruitedCompanies(companyID, origin = "", page = 1, pageSize = 20, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `recruited/infos/?company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}&origin=${origin}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosOfRecruitedCompaniesByType(
    companyID,
    infoType,
    page = 1,
    pageSize = 20,
    search = "",
    orderBy = null,
    desc = false
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `recruited/infos/?info_type=${infoType}&company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByPartnerCompany(companyID, page = 1, pageSize = 20, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `partners/infos/?company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByPartnerCompanyAndType(
    companyID,
    infoType,
    page = 1,
    pageSize = 20,
    search = "",
    orderBy = null,
    desc = false
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `partners/infos/?info_type=${infoType}&company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByParentCompany(companyID, page = 1, pageSize = 20, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `parent/infos/?company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByParentCompanyAndType(
    companyID,
    infoType,
    page = 1,
    pageSize = 20,
    search = "",
    orderBy = null,
    desc = false
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `parent/infos/?info_type=${infoType}&company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByParentCompanyInDetail2(
    companyID,
    infoType,
    page = 1,
    pageSize = 5,
    search = "",
    responsible = "",
    category = "",
    category2 = "",
    latest_change_year = "",
    status = [],
    orderBy = null,
    desc = false,
    parent = "",
    parent_protocol = "",
    field1 = "",
    department = "",
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";
    let unassigned_responsible = responsible == "unassigned" ? 1 : "";
    let unassigned_category = category == "unassigned" ? 1 : "";
    let unassigned_category2 = category2 == "unassigned" ? 1 : "";
    let unassigned_department = department == "unassigned" ? 1 : "";
    let unassigned_parent = parent == "unassigned" ? 1 : "";

    return this.get(
      `parent/infos/?info_type=${infoType}&company_id=${companyID}&page=${page}&page_size=${pageSize}&search=${search}${ordering}&responsible__id=${responsible}&status=${status}&unassigned_responsible=${unassigned_responsible}&category=${category}&unassigned_category=${unassigned_category}&category2=${category2}&unassigned_category2=${unassigned_category2}&latest_change_year=${latest_change_year}&parent=${parent}&unassigned_parent=${unassigned_parent}&parent_protocol=${parent_protocol}&field1=${field1}&department=${department}&unassigned_department=${unassigned_department}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByCompanyAndType(
    id,
    infoType,
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false,
    parent = "",
    parent_protocol = ""
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?info_type=${infoType}&page=${page}&unassigned_parent=${parent}&parent_protocol=${parent_protocol}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByCompanyAndReadReceiptActive(
    id,
    readReceiptActive = true,
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false,
    parent = "",
    parent_protocol = ""
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?readreceipt_active=${readReceiptActive}&page=${page}&unassigned_parent=${parent}&parent_protocol=${parent_protocol}&page_size=${pageSize}&search=${search}${ordering}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByCompanyAndReadReceiptActiveAndPerson(
    id,
    readReceiptActive = true,
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false,
    person = "",
    parent = "",
    parent_protocol = "",
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `companies/${id}/infos/?readreceipt_active=${readReceiptActive}&page=${page}&unassigned_parent=${parent}&parent_protocol=${parent_protocol}&page_size=${pageSize}&search=${search}${ordering}&visibletoperson=${person}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByCompanyInDetail(
    id,
    infoType,
    page = 1,
    pageSize = 5,
    search = "",
    responsible = "",
    category = "",
    latest_change_year = "",
    status = [],
    orderBy = null,
    desc = false,
    parent = "",
    viewed = null,
    signed = null,
    parent_protocol = "",
    info_ids = ""
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";
    let unassigned_responsible = responsible == "unassigned" ? 1 : "";
    let unassigned_category = category == "unassigned" ? 1 : "";

    return this.get(
      `companies/${id}/infos/?id=${info_ids}&info_type=${infoType}&page=${page}&page_size=${pageSize}&search=${search}${ordering}&responsible__id=${responsible}&status=${status}&unassigned_responsible=${unassigned_responsible}&category=${category}&unassigned_category=${unassigned_category}&latest_change_year=${latest_change_year}&parent=${parent}&parent_protocol=${parent_protocol}&viewed=${viewed}&signed=${signed}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfosByCompanyInDetail2(
    id,
    infoType,
    page = 1,
    pageSize = 5,
    search = "",
    responsible = "",
    category = "",
    category2 = "",
    latest_change_year = "",
    status = [],
    orderBy = null,
    desc = false,
    parent = "",
    parent_protocol = "",
    field1 = "",
    department = "",
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";
    let unassigned_responsible = responsible == "unassigned" ? 1 : "";
    let unassigned_category = category == "unassigned" ? 1 : "";
    let unassigned_category2 = category2 == "unassigned" ? 1 : "";
    let unassigned_department = department == "unassigned" ? 1 : "";
    let unassigned_parent = parent == "unassigned" ? 1 : "";

    return this.get(
      `companies/${id}/infos/?info_type=${infoType}&page=${page}&page_size=${pageSize}&search=${search}${ordering}&responsible__id=${responsible}&status=${status}&unassigned_responsible=${unassigned_responsible}&category=${category}&unassigned_category=${unassigned_category}&category2=${category2}&unassigned_category2=${unassigned_category2}&latest_change_year=${latest_change_year}&parent=${parent}&unassigned_parent=${unassigned_parent}&parent_protocol=${parent_protocol}&field1=${field1}&department=${department}&unassigned_department=${unassigned_department}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }


  getInfosCountByCompany(
    id,
    infoType,
    search = "",
    responsible = "",
    category = "",
    category2 = "",
    latest_change_year = "",
    parent = "",
    parent_protocol = "",
    field1 = "",
    department = "",
  ) {
    let unassigned_responsible = responsible == "unassigned" ? 1 : "";
    let unassigned_category = category == "unassigned" ? 1 : "";
    let unassigned_category2 = category2 == "unassigned" ? 1 : "";
    let unassigned_department = department == "unassigned" ? 1 : "";
    let unassigned_parent = parent == "unassigned" ? 1 : "";

    return this.get(
      `companies/${id}/infos_count/?info_type=${infoType}&search=${search}&responsible__id=${responsible}&unassigned_responsible=${unassigned_responsible}&category=${category}&unassigned_category=${unassigned_category}&category2=${category2}&unassigned_category2=${unassigned_category2}&latest_change_year=${latest_change_year}&parent=${parent}&unassigned_parent=${unassigned_parent}&parent_protocol=${parent_protocol}&field1=${field1}&department=${department}&unassigned_department=${unassigned_department}`
    ).then((response) => response.data);
  }

  getInfosByType(infoType, page = 1, pageSize = 5, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(`/infos/?info_type=${infoType}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`)
      .then((response) => response.data)
      .then((data) => PaginatedInfosResponse(data));
  }

  getInfosBySystem() {
    return this.get(`system/infos/`)
      .then((response) => response.data)
      .then((data) => InfosResponse(data));
  }

  getInfosBySystemAndType(infoType, page = 1, pageSize = 5, search = "", orderBy = null, desc = false) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `system/infos/?info_type=${infoType}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    )
      .then((response) => response.data)
      .then((data) => PaginatedInfosResponse(data));
  }

  getInfosBySystemAndStatus(
    status,
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false,
    language = null
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";

    return this.get(
      `system/infos/?language=${language}&status=${status}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    )
      .then((response) => response.data)
      .then((data) => PaginatedInfosResponse(data));
  }

  getInfosBySystemAndTypeAndCategoryAndStatus(
    infoType,
    category = "",
    status,
    page = 1,
    pageSize = 5,
    search = "",
    orderBy = null,
    desc = false,
    language = null
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";
    category = category === null ? "" : category;

    return this.get(
      `system/infos/?info_type=${infoType}&language=${language}&category=${category}&status=${status}&page=${page}&page_size=${pageSize}&search=${search}${ordering}`
    )
      .then((response) => response.data)
      .then((data) => PaginatedInfosResponse(data));
  }


  getInfosBySystemInDetail2(
    infoType,
    page = 1,
    pageSize = 5,
    search = "",
    responsible = "",
    category = "",
    category2 = "",
    latest_change_year = "",
    status = [],
    orderBy = null,
    desc = false,
    parent = "",
    parent_protocol = "",
    field1 = "",
    department = "",
    language = null
  ) {
    let column = orderBy ? this.getOrderedColumnName(orderBy, desc) : null;
    let ordering = column ? `&ordering=${column}` : "";
    let unassigned_responsible = responsible == "unassigned" ? 1 : "";
    let unassigned_category = category == "unassigned" ? 1 : "";
    let unassigned_category2 = category2 == "unassigned" ? 1 : "";
    let unassigned_department = department == "unassigned" ? 1 : "";
    let unassigned_parent = parent == "unassigned" ? 1 : "";

    return this.get(
      `system/infos/?info_type=${infoType}&language=${language}&page=${page}&page_size=${pageSize}&search=${search}${ordering}&responsible__id=${responsible}&status=${status}&unassigned_responsible=${unassigned_responsible}&category=${category}&unassigned_category=${unassigned_category}&category2=${category2}&unassigned_category2=${unassigned_category2}&latest_change_year=${latest_change_year}&parent=${parent}&unassigned_parent=${unassigned_parent}&parent_protocol=${parent_protocol}&field1=${field1}&department=${department}&unassigned_department=${unassigned_department}`
    ).then((response) => PaginatedInfosResponse(response.data));
  }

  getInfo(id) {
    return this.get(`infos/${id}/`)
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  getInfoFromPartnerCompany(id) {
    return this.get(`infos/${id}/`)
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  getInfos() {
    return this.get(`infos/`)
      .then((response) => response.data)
      .then((data) => InfosResponse(data));
  }

  updateInfo(id, gg) {
    store.dispatch("app/setPendingChanges", false);
    let info = gg;
    return this.patch(`infos/${id}/`, InfoRequest(info))
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  deleteInfo(id) {
    store.dispatch("app/setPendingChanges", false);
    return this.delete(`infos/${id}/`);
  }

  translateInfo(id) {
    return this.put(`infos/${id}/translate/`)
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  copyInfo(id) {
    return this.post(`infos/${id}/copy/`, {})
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  sentToAll(id) {
    return this.post(`system/info-sendmail/`, { info: id });
    //return this.post(`system/info-sendmail/${id}/`);
  }

  generateExternalUrl(id, include_siblings) {
    return this.post(`/infos/${id}/generateexternalurl/`, { public_siblings: include_siblings })
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  clearExternalUrl(id) {
    return this.post(`/infos/${id}/clearexternalurl/`)
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }

  getExternalInfo(v, token, id) {
    return this.get(`external/info/${v}/${token}/${id}/`)
      .then((response) => response.data)
      .then((data) => InfoResponse(data));
  }
}

export default new InfoService();
