<template>
  <div>
    <b-form-group v-if="!loading && $userIsAdmin()">
      <b-dropdown :variant="info.visible_to_user_groups.length > 0 ? 'outline-warning' : 'outline-dark'" :text="$tc('rightsInfoUserGroups')" block class="m-2" menu-class="w-100">
        <b-dropdown-form class="scroll">
          <b-button variant="outline-warning" @click="toggleEveryoneUserGroupVisibility()">
            {{ $t("clear") }}
          </b-button>
          <b-button variant="outline-dark" @click="getUserGroups()">
            {{ $t("updateList") }}
          </b-button>

          <b-form-checkbox
            v-model="info.visible_to_user_groups"
            :value="usergroup.id"
            v-for="usergroup in usergroupList"
            :key="usergroup.id"
            @change="syncUserGroupVisibility()"
          >
            {{ usergroup.name }}
          </b-form-checkbox>
          <pw-button
            cy-id="save"
            className="success"
            size="sm"
            :spinner="loading"
            :disabled="loading"
            @accepted="updateInfo()"
          >
            <i class="fa fa-save mr-2"></i>
            {{ $t("save") }}
          </pw-button>
        </b-dropdown-form>
      </b-dropdown>
    </b-form-group>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import InfoService from "@/services/InfoService";
import UserGroupService from "@/services/CompanyUserGroupService";
import Info from "@/models/Info";

export default {
  name: "InfoUserGroupVisibilityChecklist.vue",
  components: {},
  data() {
    return {
      info: new Info({}),
      editFields: false,
      loading: false,
      usergroupList: []
    };
  },
  validations: {
    info: Info.validations
  },
  props: {
    infoObject: {
      type: Object
    },
    p_id: {
      type: Number,
      required: false,
      default: null
    }
  },
  watch: {},
  mounted() {
    this.info = this.infoObject;
    if (this.info) {
      this.getUserGroups();
    } else {
      this.getInfo(infoId);
    }
  },
  methods: {
    syncUserGroupVisibility() {
      this.$emit("childToParent", this.info.visible_to_user_groups);
    },
    toggleEveryoneUserGroupVisibility() {
      //set value to empty array to allow all users to see it
      this.info.visible_to_user_groups = [];
      this.$emit("childToParent", this.info.visible_to_user_groups);
    },
    getUserGroups() {
      this.usergroupList = [];
      if (this.info.company != null) {
        UserGroupService.getUserGroupsByCompany(this.$userSelectedCompanyId()).then((usergroups) => {
          this.usergroupList = usergroups;
        });
      }
    },
    getInfo(id) {
      this.loading = true;
      InfoService.getInfo(id)
        .then((info) => {
          this.info = info;
        })
        .finally(() => (this.loading = false));
    },
    updateInfo() {
      this.loading = true;
      this.$emit("childToParent", this.info.visible_to_user_groups);
      InfoService.updateInfo(this.info.id, this.info)
        .then((info) => {
          this.$bvToast.toast(this.$t("rightsSaved"), {
            title: this.$t("saved"),
            variant: "success"
          });
        })
        .catch((error) => {
          this.$bvToast.toast(this.$t("rightsSavedError"), {
            title: this.$t("error"),
            variant: "danger"
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
<style scoped>
.scroll {
  height: 240px;
  overflow-y: auto;
}
</style>
