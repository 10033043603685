import { BaseModel } from "@/models/BaseModel";

export class FeedbackText extends BaseModel {
  constructor({ id = null, text = null, start_points, end_points }) {
    super();

    this.id = id;
    this.text = text;
    this.start_points = start_points;
    this.end_points = end_points;
  }
}

export default FeedbackText;
