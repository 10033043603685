<script>
import { mapGetters } from "vuex";

export default {
  methods: {
    $userCan(permission, resposible = null) {
      if (permission === null || permission === undefined) {
        throw "No permission passed for permission check.";
      }

      if (permission == "change_info") {
        if (this.$userIsCompanyAdmin() || (resposible != null && this.$user.person == resposible.id)) {
          return true;
        } else {
          return false;
        }
      }

      return this.hasPermission(permission);
    },
    hasPermission(permission) {
      return this.$user && this.$user.hasPermission(permission);
    },
    $userIsAdmin() {
      return this.$user && (this.$user.isAdmin || this.$user.is_supportstaff);
    },
    $userIsCompanyAdmin() {
      return this.$user && (this.$user.isCompanyAdmin || this.$user.is_supportstaff);
    },
    $userSelectedCompanyId() {
      return this.$selectedCompanyId2 ? this.$selectedCompanyId2 : this.$user.company.id;
    },
    $userIsParentAdmin() {
      return (
        this.$user &&
        this.$selectedCompanyId2 != null &&
        this.$selectedCompanyId2 != undefined &&
        this.$selectedCompanyId2 != "" &&
        this.$user.company.id != this.$selectedCompanyId2
      );
    }
  },
  computed: {
    ...mapGetters("auth", {
      $user: "user",
      $selectedCompanyId2: "selectedCompanyId2"
    })
  }
};
</script>
