import axios from "axios";
import BaseService from "./BaseService";
import UserService from "./UserService";
import CompanyService from "./CompanyService";
import store from "@/store";
import User from "@/models/User";

class AuthService extends BaseService {
  constructor() {
    super();
  }

  register(
    companyname,
    email,
    password,
    first_name,
    last_name,
    pwgroups,
    fortnox_authorization_code,
    invite,
    invite_company_id,
    partner_ref,
    sub_corona,
    preset,
    subscriptions
  ) {
    let registration = {
      email: email,
      username: email,
      companyname: companyname,
      first_name: first_name,
      last_name: last_name,
      pwgroups: pwgroups,
      password1: password,
      password2: password,
      fortnox_authorization_code: fortnox_authorization_code,
      invite: invite,
      invite_company_id: invite_company_id,
      partner_ref: partner_ref,
      sub_corona: sub_corona,
      preset: preset,
      subscriptions
    };

    // Backend doesn't like null value
    if (!registration.preset) {
      delete registration.preset;
    }

    if (registration.pwgroups) {
      for (var i = 0; i < registration.pwgroups.length; i++) {
        registration.pwgroups[i] = registration.pwgroups[i].id;
      }
    }

    return new Promise((resolve, reject) => {
      return this.post("rest-auth/registration/", registration)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetPassword(email) {
    let password_reset = {
      email: email
    };

    return new Promise((resolve, reject) => {
      return this.post("rest-auth/password/reset/", password_reset)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  resetPasswordConfirm(new_password1, new_password2, uid, token) {
    let change_password = {
      new_password1: new_password1,
      new_password2: new_password2,
      uid: uid,
      token: token
    };
    return new Promise((resolve, reject) => {
      return this.post("rest-auth/password/reset/confirm/", change_password)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  changePassword(passwordChange) {
    return new Promise((resolve, reject) => {
      return this.post("rest-auth/password/change/", passwordChange)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  createPDF(threats, name, riskData, lang, financial, strategic, operational, other) {
    let PDF = {
      threats: threats,
      name: name,
      riskData: riskData,
      lang: lang,
      financial: financial,
      strategic: strategic,
      operational: operational,
      other: other
    };

    return new Promise((resolve, reject) => {
      return this.post("pdf/", PDF)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  login(username, password, fortnox_authorization_code) {
    let credentials = {
      username: username,
      password: password,
      fortnox_authorization_code: fortnox_authorization_code
    };

    // In case we have an old invalid token in header we clear it
    delete axios.defaults.headers.common["Authorization"];

    return new Promise((resolve, reject) => {
      return this.post("rest-auth/login/", credentials)
        .then((response) => {
          let token = response.data["key"];
          localStorage.setItem("token", token);

          return UserService.getMyProfile()
            .then((user) => {
              store.dispatch("app/setFrontendLanguage", user.active_language);
              store.dispatch(
                "app/setBackendLanguage", user.active_language
              );
              store.dispatch("auth/setUser", user);

              CompanyService.getMyAvailableCompanies().then((companies) => {
                store.dispatch("auth/setAvailableCompanies", companies);

                resolve(response);
              }).catch((error) => {
                reject(error)
              })
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  logout() {
    store.dispatch("app/clearData");
    return this.post("rest-auth/logout/");
  }

  sendMail(p_subject, p_body) {
    let body = {
      subject: p_subject,
      body: p_body
    };

    return new Promise((resolve, reject) => {
      return this.post("auth/send_email/", body)
        .then((response) => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  sendMailFromPublic(p_subject, p_body) {
    let body = {
      subject: p_subject,
      body: p_body
    };

    return this.post("auth/send_email_public/", body);
  }
}

export default new AuthService();
