<template>
  <b-modal
    :visible="!connected"
    :title="$t('disconnected')"
    centered
    :hide-header-close="false"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <p class="my-4">
      <i class="fa fa-network-wired fa-lg mx-4" />
      {{ $t("errors.disconnected") }}
    </p>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ModalHandler",
  props: {},
  data() {
    return {};
  },
  computed: {
    ...mapGetters("app", ["connected"])
  }
};
</script>

<style scoped lang="scss"></style>
