import { required } from "vuelidate/lib/validators";
import { BaseModel } from "@/models/BaseModel";
import { Company } from "@/models/Company";
import { Document } from "@/models/Document";
import { RefresherCategory } from "@/models/RefresherCategory";
import { PersonRefresher } from "@/models/PersonRefresher";
import { Person } from "@/models/Person";

export class Refresher extends BaseModel {
  constructor({
    id = null,
    name,
    comment,
    consequence = 1,
    duration = 1,
    interval,
    mandatory = false,
    person_count,
    company = {},
    category = null,
    person_refreshers = [],
    position,
    refresher_type,
    documents = [],
    responsible
  }) {
    super();

    this.id = id;
    this.name = name;
    this.comment = comment;
    this.consequence = consequence;
    this.duration = duration;
    this.interval = interval;
    this.mandatory = mandatory;
    this.person_count = person_count;
    this.company = new Company(company);
    this.category = category ? new RefresherCategory(category) : null;
    this.person_refreshers = person_refreshers.map((pc) => new PersonRefresher(pc));
    this.position = position;
    this.refresher_type = refresher_type;
    this.documents = documents.map((d) => new Document(d));
    this.responsible = responsible ? new Person(responsible) : null;
  }

  static get validations() {
    const validations = {
      name: {
        required
      },
      consequence: {
        required
      }
    };

    return validations;
  }
}

export default Refresher;
