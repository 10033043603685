import "@fortawesome/fontawesome-free/css/all.css";
import axios from "axios";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vue from "vue";
import VueTouch from "vue-touch";
import Vuelidate from "vuelidate";
import App from "./App.vue";
import "./components";
import "./filters";
import "./mixins";
import i18n from "./helpers/language";
import svgSpriteLoader from "./helpers/svg-sprite-loader";
import router from "./router";
import "./sass/theme.scss";
import store from "./store";
import InterceptorSetup from "@/services/interceptors/InterceptorSetup";
import VueScrollTo from "vue-scrollto";
import VueAnalytics from "vue-analytics";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Vue2Filters from "vue2-filters";
import VueSignaturePad from "vue-signature-pad";
import VueCryptojs from "vue-cryptojs";
import IdleVue from "idle-vue";

Chart.plugins.unregister(ChartDataLabels);

const __svg__ = {
  path: "./assets/images/icons/*.svg",
  name: "assets/images/[hash].sprite.svg"
};
svgSpriteLoader(__svg__.filename);

InterceptorSetup();

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueTouch);
Vue.use(Vuelidate);
Vue.use(VueScrollTo);
Vue.use(Vue2Filters);
Vue.use(VueSignaturePad);
Vue.use(VueCryptojs);

const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 45,
  startAtIdle: false
});

Vue.config.productionTip = false;

const isProd = process.env.NODE_ENV === "production";
Vue.use(VueAnalytics, {
  id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID,
  router,
  autoTracking: {
    exception: true
  },
  debug: {
    enabled: !isProd,
    sendHitTask: isProd
  }
});

let token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = "Token " + token;
}

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
