<script>
export default {
  methods: {
    setFilterSettings() {
      localStorage.setItem("filterRoutePath", JSON.stringify(this.$route.fullPath));
      localStorage.setItem("filter", JSON.stringify(this.filter));
    },
    updateURL() {
      var params = new URLSearchParams();
      if (this.filter.search !== "") {
        params.set("search", this.filter.search);
      }
      if (this.filter.responsible !== "") {
        params.set("responsible", this.filter.responsible);
      }
      if (this.filter.status.length) {
        params.set("status", this.filter.status);
      }
      if (this.filter.category !== "") {
        params.set("category", this.filter.category);
      }
      if (this.filter.category2 && this.filter.category !== "") {
        params.set("category2", this.filter.category2);
      }
      if (this.filter.latest_change_year !== "") {
        params.set("latest_change_year", this.filter.latest_change_year);
      }
      history.replaceState(null, null, "?" + params.toString());
    }
  }
};
</script>
