import { BaseModel } from "@/models/BaseModel";

export class Document extends BaseModel {
  constructor({ id = null, creation_date, latest_change, creator, company, name = "", description = "", filepath }) {
    super();

    this.id = id;
    this.creation_date = creation_date;
    this.latest_change = latest_change;
    this.creator = creator;
    this.company = company;
    this.name = name;
    this.description = description;
    this.filepath = filepath;
  }

  copy() {
    // Overridden as file fields won't be copied using JSON.stringify...
    let copy = super.copy();
    copy.filepath = new File([this.filepath], this.name);
    return copy;
  }
}

export default Document;
