import BaseModel from "@/models/BaseModel";
import Competence from "@/models/Competence";
import Person from "@/models/Person";

export class PersonCompetence extends BaseModel {
  constructor({ id = null, level, competence, person }) {
    super();

    this.id = id;
    this.level = level;
    this.competence = competence ? new Competence(competence) : null;
    this.person = person ? new Person(person) : null;
  }
}

export default PersonCompetence;
