import { BaseModel } from "@/models/BaseModel";
import { Option } from "@/models/Option";
import { Question } from "@/models/Question";

export class Condition extends BaseModel {
  constructor({ id = null, condition_question = null, condition_option = null }) {
    super();

    this.id = id;
    this.condition_question = condition_question ? new Question(condition_question) : null;
    this.condition_option = condition_option ? new Option(condition_option) : null;
  }
}

export default Condition;
