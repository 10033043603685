import Vue from "vue";
import HasPermission from "./mixins/HasPermission";
import PrintWindow from "./mixins/PrintWindow";
import FilterSettings from "./mixins/FilterSettings";
import BadgeVariants from "./mixins/BadgeVariants";

Vue.mixin(HasPermission);
Vue.mixin(PrintWindow);
Vue.mixin(FilterSettings);
Vue.mixin(BadgeVariants);
