import { BaseModel } from "@/models/BaseModel";

export class FocusArea extends BaseModel {
  constructor({ id = null, name = "", colour = null }) {
    super();

    this.id = id;
    this.name = name;
    this.colour = colour;
  }

  // static get validations() {
  //   const validations = {
  //     name: {
  //       maxLength: maxLength(255)
  //     },
  //     start_date: { required: required },
  //     end_date: { required: required },
  //     questionnaire: { required: required },
  //     description: {}
  //   };

  //   return validations;
  // }
}

export default FocusArea;
