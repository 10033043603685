import { BaseModel } from "@/models/BaseModel";

export class Permission extends BaseModel {
  constructor({ name, codename }) {
    super();

    this.name = name;
    this.codename = codename;
  }
}

export default Permission;
