import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import auth from "./auth";
import constants from "./constants";
import layout from "./layout";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app,
    auth,
    constants,
    layout
  }
});
