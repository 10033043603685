import { BaseModel } from "@/models/BaseModel";

export class RefresherCategory extends BaseModel {
  constructor({ id = null, name, company, refresher_type }) {
    super();

    this.id = id;
    this.name = name;
    this.company = company;
    this.refresher_type = refresher_type;
  }

  get isCreatedBySystem() {
    return !this.company;
  }
}

export default RefresherCategory;
