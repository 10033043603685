import { BaseModel } from "@/models/BaseModel";
import { Document } from "@/models/Document";

export class Option extends BaseModel {
  constructor({
    id = null,
    text = null,
    identifier,
    feedback,
    points = 0,
    position = 0,
    image = null
    // question = null // TODO?
  }) {
    super();
    this.id = id;
    this.text = text;
    this.identifier = identifier;
    this.feedback = feedback;
    this.points = points;
    this.position = position;
    this.image = image ? new Document(image) : null;
  }
}

export default Option;
