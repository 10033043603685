import { BaseModel } from "@/models/BaseModel";
import User from "./User";

export class UserGroup extends BaseModel {
  constructor({
    id,
    name = "",
    company = null,
    users = [],
    show_policy_dashboard,
    show_policy_fingerprint,
    show_policy_policy,
    show_policy_routine,
    show_policy_instructions,
    show_policy_goals,
    show_policy_organisation,
    show_policy_planning,
    show_policy_risk,
    show_policy_iso_risk,
    show_policy_news,
    show_policy_contract,
    show_policy_meeting,
    show_policy_manager,
    show_policy_description,
    show_policy_companybinder,
    show_employees_manual,
    show_employees_people,
    show_employees_documentation,
    show_employees_medicalcheck,
    show_employees_training,
    show_employees_competence,
    show_employees_rehabs,
    show_employees_newhire,
    show_employees_notes,
    show_employees_survey,
    show_employees_external,
    show_employees_ideabox,
    show_employees_bingo,
    show_employees_absence,
    show_operations_policy,
    show_operations_routine,
    show_operations_instructions,
    show_operations_goals,
    show_operations_supplier_control,
    show_operations_risk,
    show_operations_think,
    show_operations_legislation,
    show_operations_accountability,
    show_operations_claim,
    show_operations_rounds,
    show_operations_investigation,
    show_operations_permission,
    show_operations_health,
    show_quality_dashboard,
    show_quality_process_map,
    show_quality_policy,
    show_quality_routine,
    show_quality_instructions,
    show_quality_goals,
    show_quality_iso_risk,
    show_quality_accountability,
    show_quality_stakeholder,
    show_quality_supplier_control,
    show_quality_approved_supplier,
    show_quality_documentation,
    show_quality_customer_surveys,
    show_quality_checklists,
    show_quality_hazard,
    show_quality_audit,
    show_quality_deviations,
    show_quality_legislation,
    show_quality_products,
    show_sustainability_policy,
    show_sustainability_routine,
    show_sustainability_instructions,
    show_sustainability_goals,
    show_sustainability_iso_risk,
    show_sustainability_accountability,
    show_sustainability_documentation,
    show_sustainability_stakeholder,
    show_sustainability_supplier_control,
    show_sustainability_approved_supplier,
    show_sustainability_legislation,
    show_sustainability_checklists,
    show_sustainability_audit,
    show_sustainability_hazard,
    show_sustainability_deviations,
    show_gdpr_data,
    show_gdpr_documentation,
    show_gdpr_checklists,
    show_marketplace_service,
    show_marketplace_training,
    show_marketplace_documentation,
    show_marketplace_people,
    show_control_plans,
    show_control_myactivities,
    show_control_activities,
    show_control_gantt,
    show_other_product,
    show_other_chem,
    show_other_followup,
    show_other_workplaces,
    show_other_tips,
    show_other_checklists,
    show_custom_questionnaires,
    show_whistleblowerreports,
    show_hseq_projects,
    show_clients,
    show_ropa,
    show_crisis,
    show_digital,
    show_fire,
    show_employees_company_culture,
    show_employees_wellness,
    show_employees_jobdescription,
    show_reports,
    show_crm,
    show_doers_basic,
    show_doers_advanced,
    show_doers_operational_status,
    show_keeada_basic,
    show_keeada_advanced,
    show_riktigtgruppen_basic,
    show_riktigtgruppen_advanced
  }) {
    super();

    this.id = id;
    this.name = name;
    this.users = users;
    this.company = company ? new Company(company) : null;

    // Feature toggles admin
    // General
    this.show_policy_dashboard = show_policy_dashboard;
    this.show_policy_fingerprint = show_policy_fingerprint;
    this.show_policy_policy = show_policy_policy;
    this.show_policy_routine = show_policy_routine;
    this.show_policy_instructions = show_policy_instructions;
    this.show_policy_goals = show_policy_goals;
    this.show_policy_organisation = show_policy_organisation;
    this.show_policy_planning = show_policy_planning;
    this.show_policy_risk = show_policy_risk;
    this.show_policy_iso_risk = show_policy_iso_risk;
    this.show_policy_news = show_policy_news;
    this.show_policy_contract = show_policy_contract;
    this.show_policy_meeting = show_policy_meeting;
    this.show_policy_manager = show_policy_manager;
    this.show_policy_description = show_policy_description;
    this.show_policy_companybinder = show_policy_companybinder;
    // Coworkers
    this.show_employees_manual = show_employees_manual;
    this.show_employees_people = show_employees_people;
    this.show_employees_documentation = show_employees_documentation;
    this.show_employees_medicalcheck = show_employees_medicalcheck;
    this.show_employees_training = show_employees_training;
    this.show_employees_competence = show_employees_competence;
    this.show_employees_rehabs = show_employees_rehabs;
    this.show_employees_newhire = show_employees_newhire;
    this.show_employees_notes = show_employees_notes;
    this.show_employees_survey = show_employees_survey;
    this.show_employees_external = show_employees_external;
    this.show_employees_ideabox = show_employees_ideabox;
    this.show_employees_bingo = show_employees_bingo;
    this.show_employees_absence = show_employees_absence;
    // Work environment
    this.show_operations_policy = show_operations_policy;
    this.show_operations_routine = show_operations_routine;
    this.show_operations_instructions = show_operations_instructions;
    this.show_operations_goals = show_operations_goals;
    this.show_operations_supplier_control = show_operations_supplier_control;
    this.show_operations_risk = show_operations_risk;
    this.show_operations_think = show_operations_think;
    this.show_operations_legislation = show_operations_legislation;
    this.show_operations_accountability = show_operations_accountability;
    this.show_operations_claim = show_operations_claim;
    this.show_operations_rounds = show_operations_rounds;
    this.show_operations_investigation = show_operations_investigation;
    this.show_operations_permission = show_operations_permission;
    this.show_operations_health = show_operations_health;
    this.show_employees_company_culture = show_employees_company_culture;
    this.show_employees_wellness = show_employees_wellness;
    this.show_employees_jobdescription = show_employees_jobdescription;
    // Quality
    this.show_quality_dashboard = show_quality_dashboard;
    this.show_quality_process_map = show_quality_process_map;
    this.show_quality_policy = show_quality_policy;
    this.show_quality_routine = show_quality_routine;
    this.show_quality_instructions = show_quality_instructions;
    this.show_quality_goals = show_quality_goals;
    this.show_quality_iso_risk = show_quality_iso_risk;
    this.show_quality_accountability = show_quality_accountability;
    this.show_quality_stakeholder = show_quality_stakeholder;
    this.show_quality_supplier_control = show_quality_supplier_control;
    this.show_quality_approved_supplier = show_quality_approved_supplier;
    this.show_quality_documentation = show_quality_documentation;
    this.show_quality_customer_surveys = show_quality_customer_surveys;
    this.show_quality_checklists = show_quality_checklists;
    this.show_quality_hazard = show_quality_hazard;
    this.show_quality_audit = show_quality_audit;
    this.show_quality_deviations = show_quality_deviations;
    this.show_quality_legislation = show_quality_legislation;
    this.show_quality_products = show_quality_products;
    // Sustainability
    this.show_sustainability_policy = show_sustainability_policy;
    this.show_sustainability_routine = show_sustainability_routine;
    this.show_sustainability_instructions = show_sustainability_instructions;
    this.show_sustainability_goals = show_sustainability_goals;
    this.show_sustainability_iso_risk = show_sustainability_iso_risk;
    this.show_sustainability_accountability = show_sustainability_accountability;
    this.show_sustainability_documentation = show_sustainability_documentation;
    this.show_sustainability_stakeholder = show_sustainability_stakeholder;
    this.show_sustainability_supplier_control = show_sustainability_supplier_control;
    this.show_sustainability_approved_supplier = show_sustainability_approved_supplier;
    this.show_sustainability_legislation = show_sustainability_legislation;
    this.show_sustainability_checklists = show_sustainability_checklists;
    this.show_sustainability_audit = show_sustainability_audit;
    this.show_sustainability_hazard = show_sustainability_hazard;
    this.show_sustainability_deviations = show_sustainability_deviations;
    // GDPR
    this.show_gdpr_data = show_gdpr_data;
    this.show_gdpr_documentation = show_gdpr_documentation;
    this.show_gdpr_checklists = show_gdpr_checklists;
    // Marketplace
    this.show_marketplace_service = show_marketplace_service;
    this.show_marketplace_training = show_marketplace_training;
    this.show_marketplace_documentation = show_marketplace_documentation;
    this.show_marketplace_people = show_marketplace_people;
    // Activity plan
    this.show_control_plans = show_control_plans;
    this.show_control_myactivities = show_control_myactivities;
    this.show_control_activities = show_control_activities;
    this.show_control_gantt = show_control_gantt;
    // Other
    this.show_other_product = show_other_product;
    this.show_other_chem = show_other_chem;
    this.show_other_followup = show_other_followup;
    this.show_other_workplaces = show_other_workplaces;
    this.show_other_tips = show_other_tips;
    this.show_other_checklists = show_other_checklists;
    this.show_custom_questionnaires = show_custom_questionnaires;
    //  Whistleblower Report
    this.show_whistleblowerreports = show_whistleblowerreports;
    //HSEQ Projects
    this.show_hseq_projects = show_hseq_projects;
    //Clients
    this.show_clients = show_clients;
    //Ropa
    this.show_ropa = show_ropa;
    //Crisis
    this.show_crisis = show_crisis;
    //Digital
    this.show_digital = show_digital;
    //Fire
    this.show_fire = show_fire;
    //Reports
    this.show_reports = show_reports;
    // CRM
    this.show_crm = show_crm;
    //Partners
    this.show_doers_basic = show_doers_basic;
    this.show_doers_advanced = show_doers_advanced;
    this.show_doers_operational_status = show_doers_operational_status;
    this.show_keeada_basic = show_keeada_basic;
    this.show_keeada_advanced = show_keeada_advanced;
    this.show_riktigtgruppen_basic = show_riktigtgruppen_basic;
    this.show_riktigtgruppen_advanced = show_riktigtgruppen_advanced;
  }
}

export default UserGroup;
