<template>
  <div>
    <modal-handler />
    <router-view />
  </div>
</template>
<script>
import ModalHandler from "@/components/ModalHandler";
import AzureAuthService from "@/services/AzureAuthService";
import { mapActions } from "vuex";
import CompanyService from "@/services/CompanyService";
export default {
  components: {
    ModalHandler
  },
  mounted() {
    // Set favicon

    var hostname = document.location.hostname;
    var partnerRef = null;

    if (hostname == "www.app.keeada.eu" || hostname == "app.keeada.eu") {
      partnerRef = "keeada";
    } else if (hostname == "www.app.isocertifiering.nu" || hostname == "app.isocertifiering.nu") {
      partnerRef = "isocertifiering";
    } else if (hostname == "www.app.qbic.se" || hostname == "app.qbic.se") {
      partnerRef = "qbic";
    }

    if (partnerRef && !this.$partner) {
      this.$setIsLoadingPartner(true);
      CompanyService.getPartnerByPartnerRef(partnerRef)
        .then((company) => {
          this.$setPartner(company);
          if (company.favicon != null) {
            const favicon = document.getElementById("favicon");
            favicon.href = company.favicon.filepath;
          }

          if (company.favicon != null) {
            window.document.title = partnerRef;
          }
        })
        .finally(() => {
          this.$setIsLoadingPartner(false);
        });
    }
  },
  computed: {
    isIdle() {
      return this.$store.state.idleVue.isIdle;
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("layout", {
      $setPartner: "setPartner",
      $setIsLoadingPartner: "setIsLoadingPartner"
    })
  },
  created() {
    // this.interval = setInterval(() => {
    //   let token = localStorage.getItem("token");
    //   if (token && token.toLowerCase().startsWith("ey") && this.isIdle) {
    //     this.logout().then(() => {
    //       this.$router.push({ name: "login" });
    //       sessionStorage.clear();
    //       localStorage.clear();
    //     });
    //   } else {
    //     if (token && token.toLowerCase().startsWith("ey")) {
    //       AzureAuthService.refreshtoken();
    //     }
    //   }
    // }, 1000 * 60 * 45);
  }
};
</script>
