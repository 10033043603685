import { UserGroup } from "@/models/UserGroup";

export const UserGroupRequest = (argUserGroup) => {
  let usergroup = argUserGroup.copy();

  if (!usergroup.id) {
    delete usergroup.id;
  }

  for (let i = 0; i < usergroup.users.length; i++) {
    usergroup.users[i] = usergroup.users[i].id;
  }

  return usergroup;
};

export const UserGroupResponse = (usergroup) => {
  return new UserGroup(usergroup);
};

export const UserGroupsResponse = (usergroup) => {
  return usergroup.map((p) => UserGroupResponse(p));
};
