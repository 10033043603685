import moment from "moment";
import Vue from "vue";

Vue.filter("datetime", function (value, format = "YYYY-MM-DD HH:mm:ss") {
  if (value) {
    if (format === "fromNow") {
      return moment(value).fromNow();
    } else {
      return moment(value).format(format);
    }
  }
});

Vue.filter("date", function (value, format = "YYYY-MM-DD") {
  if (value) {
    if (format === "fromNow") {
      return moment(value).fromNow();
    } else {
      return moment(value).format(format);
    }
  }
});

Vue.filter("fullMonthName", function (value, format = "MMMM") {
  if (value) {
    return moment(value).format(format);
  }
});
