export class BaseModel {
  constructor() {}

  get isNew() {
    return this.id == null || this.id == undefined;
  }

  // Will make an object deep copy that can be used to init a new model
  copy() {
    return JSON.parse(JSON.stringify(this));
  }
}

export default BaseModel;
