import moment from "moment";
import { BaseModel } from "@/models/BaseModel";
import { Person } from "@/models/Person";
export class Company extends BaseModel {
  constructor({
    id = null,
    name = null,
    content_language,
    creation_date,
    org_nr,
    phone,
    email,
    address,
    zip,
    city,
    country,
    www,
    partner_ref = null,
    parent = null,
    children = [],
    whistleblowerreport_ref,
    partner_welcome_text = null,
    whistleblower_welcome_text = null,
    recruiting_partner,
    whistleblower_partner,
    is_whistleblowing_company,
    advanced_mode,
    sub_corona,
    subscriptions = [],
    has_fortnox_integration,
    misc = "",
    hseq_responsible,
    logo = null,
    favicon = null,
    color = null,
    SWOT_strengths = "",
    SWOT_weaknesses = "",
    SWOT_opportunities = "",
    SWOT_threats = "",
    info_what = "",
    info_where = "",
    info_howLong = "",
    info_economy = "",
    info_features = "",
    info_own = "",
    info_goals = "",
    info_why = "",
    info_nrOfEmployees = "",
    BMC_offers,
    BMC_partners = "",
    BMC_activities = "",
    BMC_resources = "",
    BMC_segments = "",
    BMC_relations = "",
    BMC_distribution = "",
    BMC_cost = "",
    BMC_income = "",
    can_edit_users = true,
    can_edit_users_in_usergroups = true,
    can_edit_organisation = true,
    notifications_enabled,
    has_planday_integration
  }) {
    super();

    this.id = id;
    this.name = name;
    this.content_language = content_language;
    this.creation_date = creation_date ? moment(creation_date) : null;
    this.org_nr = org_nr;
    this.phone = phone;
    this.email = email;
    this.address = address;
    this.zip = zip;
    this.city = city;
    this.country = country;
    this.www = www;
    this.partner_ref = partner_ref;
    this.children = children.length > 0 ? children.map((c) => new this.constructor(c)) : [];
    this.parent = parent ? new this.constructor(parent) : null;
    this.partner_welcome_text = partner_welcome_text;
    this.whistleblower_welcome_text = whistleblower_welcome_text;
    this.recruiting_partner = recruiting_partner ? new this.constructor(recruiting_partner) : null;
    this.whistleblower_partner = whistleblower_partner ? new this.constructor(whistleblower_partner) : null;
    this.is_whistleblowing_company = is_whistleblowing_company;
    this.whistleblowerreport_ref = whistleblowerreport_ref;
    this.advanced_mode = advanced_mode;
    this.sub_corona = sub_corona;
    this.subscriptions = subscriptions;
    this.has_fortnox_integration = has_fortnox_integration;
    this.misc = misc;
    this.hseq_responsible = hseq_responsible ? new Person(hseq_responsible) : null;
    this.logo = logo;
    this.color = color;
    this.favicon = favicon;
    this.SWOT_strengths = SWOT_strengths;
    this.SWOT_weaknesses = SWOT_weaknesses;
    this.SWOT_opportunities = SWOT_opportunities;
    this.SWOT_threats = SWOT_threats;
    this.info_what = info_what;
    this.info_where = info_where;
    this.info_howLong = info_howLong;
    this.info_economy = info_economy;
    this.info_features = info_features;
    this.info_own = info_own;
    this.info_goals = info_goals;
    this.info_why = info_why;
    this.info_nrOfEmployees = info_nrOfEmployees;
    this.BMC_offers = BMC_offers;
    this.BMC_partners = BMC_partners;
    this.BMC_activities = BMC_activities;
    this.BMC_resources = BMC_resources;
    this.BMC_segments = BMC_segments;
    this.BMC_relations = BMC_relations;
    this.BMC_distribution = BMC_distribution;
    this.BMC_cost = BMC_cost;
    this.BMC_income = BMC_income;
    this.can_edit_users = can_edit_users;
    this.can_edit_users_in_usergroups = can_edit_users_in_usergroups;
    this.can_edit_organisation = can_edit_organisation;
    this.notifications_enabled = notifications_enabled;
    this.has_planday_integration = has_planday_integration;

    this.companyParamsArray = this.parseMisc(misc);
  }

  get isPartner() {
    return this.partner_ref !== null && this.partner_ref !== undefined;
  }

  get hasParent() {
    return this.parent !== null && this.parent !== undefined;
  }

  get isParent() {
    return this.children.length > 0;
  }

  parseMisc(misc) {
    if (misc && this.isValidJSON(misc)) {
      return JSON.parse(misc);
    } else {
      return [];
    }
  }

  isValidJSON(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  hasModule(module) {
    let prefix = "has_";
    return this.subscriptions && this.subscriptions.some((s) => s[prefix + module]);
  }
}

export default Company;
